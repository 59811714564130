.external-fonts-loaded body, .external-fonts-loaded h1, .external-fonts-loaded h2, .external-fonts-loaded h3, .external-fonts-loaded h4, .external-fonts-loaded h5, .external-fonts-loaded h6, .external-fonts-loaded .h1, .external-fonts-loaded .h2, .external-fonts-loaded .h3, .external-fonts-loaded .h4, .external-fonts-loaded .h5, .external-fonts-loaded .h6, .external-fonts-loaded .cart-table .main-link, .external-fonts-loaded .cart-related-name,.external-fonts-loaded ol.cart-header, .external-fonts-loaded .next-step, .external-fonts-loaded .price-wrapper, .external-fonts-loaded .price-additional, .external-fonts-loaded .price-measure, .external-fonts-loaded .price-final, .external-fonts-loaded .price-save, .external-fonts-loaded .price-standard, .external-fonts-loaded .btn, .external-fonts-loaded .products .name{font-family: 'Montserrat', sans-serif;}

.bannersright{position:absolute;left:83%;}
.bannersright p:not(strong),.bannersleft p:not(strong){font-size:12px;}
.bannersright p,.bannersleft p{color:#fff;max-width:200px;}
.bannersright img{margin-bottom:5px;}
.bannersleft{position:absolute;right:83%;}
.bannersleft img{margin-bottom:5px;}

/*košík*/
.ordering-process #header{border-bottom:0;}


a.login{padding-left:15px;}

#content {display:flex;flex-direction:column;}
#content .filters{display:block;}
#filters .filter-sections{display:none;}

/*kategorie*/
.slider-wrapper{margin-top:20px;}
.filters-wrapper{order:-1;}
.subcategories.with-image{order:-2}
.subcategories.with-image li a{flex-direction:column;text-align:center;padding-left:0;border:0;color:#000000;height:auto;padding:0;}
.subcategories.with-image li a .image img{max-height:100px;}
.subcategories.with-image li a .image{width:100%;;text-align:center}
.subcategories.with-image li a .text{font-size: 12px;font-weight: 600;text-transform: uppercase;width:100%;padding-left:0;display:contents;}
.subcategories li a::after,.subcategories li a::before{display:none;}
.category-perex{order:-3}
body:not(.type-index) .content-wrapper{border-bottom-left-radius:10px;border-bottom-right-radius:10px;}
.pagination-link.up::before,.pagination-link.next::before{color:#1b1464;}
#content .btn.unveil-button{color: #1b1464;font-size: 13px;font-weight: 600;text-transform: uppercase;letter-spacing: 1.3px;background:#ebebeb;border-radius:25px;border-color:#ebebeb;}
#content .unveil-button:hover{background-color:#000;color:#fff;border-color:#000;}
.category-header div{display:none;}
.category-header {background-color:transparent;justify-content:center;margin:0px 0 15px;}
h1.category-title{font-size: 35px;font-weight: 600;padding:0;margin:40px 0;color:#000;text-align:center;order:-4}
.category-header input[type="radio"]+label{color: #333;font-size: 14px;font-weight: 600;}
.category-header input[type="radio"]:checked+label{color:#808080;}

/*drobečky*/
.breadcrumbs{border-top-left-radius:10px;border-top-right-radius:10px;}
.breadcrumbs>span>a::before{display:none;}
.last{color: #4d4d4d;}
.breadcrumbs>span>a::after {content:'\e910';font-family:shoptet;display:flex;transform:rotate(0);bottom: inherit;background-color: transparent;}
.navigation-home-icon::after{margin-top:-5px;}
.breadcrumbs{border-bottom:0;display:flex;justify-content:center;color: #999999;font-size: 11px;font-weight: 600;line-height: 29.14px;}
.navigation-home-icon-wrapper::before{content:url('/user/documents/upload/šablona-nemazat/home-drobecek.png');position:inherit;display:flex;align-items:center;top:inherit;margin-top:-5px;}
.navigation-home-icon-wrapper #navigation-first a{padding-left:5px;;}

/*menu*/
.navigation-in>ul>li.splitted {visibility: visible;}
.menu-helper-visible .navigation-in{width:100%}.submenu-visible .navigation-in > ul > li.splitted,.menu-helper-visible .navigation-in>ul>li.splitted{display:block}
.navigation-in>ul>li.ext.exp>ul{z-index:9999;}
.navigation-in>ul>li.ext.exp>ul{top:inherit;}


.navigation-in>ul>li>a {color: #fff;font-size: 14px;font-weight: 600;line-height: 17px;}
.navigation-in ul li a b, .menu-helper ul li a b { font-weight: 600;}
.menu-helper{display:none;}
.navigation-in>ul>li {border-left-color: rgba(0,0,0,);background: #000; margin-bottom: 10px;}


/*patička*/
footer#footer span.instagram a{padding-left:27px;}
.eet-message-inner{max-width:100%;padding-right:0;}
#signature{text-align:center;}
#footer .contact-box a:hover, #footer .password-helper a:hover, #footer .news-item-widget a:hover, #footer ul a:hover,footer#footer .contact-box ul li>span.mail a:hover,footer#footer .contact-box ul li>span.tel a:hover{color:#fff;}
footer#footer .contact-box ul li>span.facebook a:hover, footer#footer .contact-box ul li>span.instagram a:hover{background-color:#fff;color:#808080;}
footer#footer .contact-box ul li>span.facebook a:hover::after, footer#footer .contact-box ul li>span.instagram a:hover::after{color:#808080;}
footer#footer .contact-box ul li>span.facebook a::after{    content: '\e900';font-family:shoptet;color:#fff;font-size:26px;margin-left:16px;}
footer#footer .contact-box ul li>span.instagram a::after{    content: '\e909';font-family:shoptet;color:#fff;font-size:26px;margin-left:16px;}
footer#footer .contact-box ul li>span.facebook::before,.contact-box ul li>span.instagram::before {content: '';}
footer#footer .contact-box ul li>span.facebook a, footer#footer .contact-box ul li>span.instagram a{border:1px solid;display:flex;align-items:center;border-radius:24px;}
footer#footer .contact-box ul li>span.facebook a,footer#footer .contact-box ul li>span.instagram a{color: #ffffff;font-family: "Open Sans";font-size: 14px;font-weight: 600;line-height: 29.14px;padding:10px 12px;}
#footer h4{color: #ffffff;font-size: 15px;font-weight: 00;text-transform: uppercase;letter-spacing: 0.75px;}
#footer .contact-box a, #footer .password-helper a, #footer .news-item-widget a, #footer ul a{color: #808080;font-family: "Open Sans";font-size: 14px;font-weight: 600;line-height: 29.14px;}
.copyright{color: #808080;font-family: "Open Sans";font-size: 12px;font-weight: 600;line-height: 29.14px;}
footer#footer .contact-box ul li>span.tel::before,footer#footer .contact-box ul li>span.mail::before{display:none;}
footer#footer .contact-box ul li>span{padding-left:0;}
footer#footer .contact-box ul li>span.tel a{font-family: "Open Sans";color: #808080;font-size: 20px;}
footer#footer .contact-box ul li>span.mail a{font-size: 14px;color: #808080;font-family: "Open Sans";font-weight: 600;}


/*produkty*/
.products>div, .columns-4 .products.products-block>div{border-color:transparent}
.p .name{color: #000000;font-size: 16px;font-weight: 600;}
.p .name:hover{color:#808080;}
.products .p-bottom .p-desc{color: #808080;font-size: 11px;font-weight: 600;}
.price-final{font-size: 16px;font-weight: 600;}
.btn.btn-conversion, a.btn.btn-conversion, .btn.btn-cart, a.btn.btn-cart{font-size: 16px;font-weight: 600;}
.btn.btn-cart::before, a.btn.btn-cart::before, .btn.add-to-cart-button::before, a.btn.add-to-cart-button::before{content:"";}
.products-block .ratings-wrapper .availability{font-size: 11px;font-weight: 600;}

/*hlavička*/
#header .site-name a img{max-height:93px;}
.btn.cart-count::before, a.btn.cart-count::before{content:url('/user/documents/upload/šablona-nemazat/kosik-ico.png');}
.header-top .cart-count span{color: #ffffff;font-size: 16px;font-weight: 600;letter-spacing: 0.53px;text-transform:lowercase;}

header#header .search button[type="submit"]::before{content:"\e920";font-family:shoptet;color:#999;}
header#header .search button[type="submit"]{background-color:transparent;border-color:transparent;}
header#header .search input[type*="search"] {    border: none;    border-radius: 30px;     color: #999999;font-size: 14px;font-weight: 600;background-color:rgba(255,255,255,0.3);}
.top-navigation-bar{background-color:transparent;border:0;}
.top-navigation-bar-menu{display:none}
.top-navigation-bar .container>div:first-child{display:none;}
a.registrace{border-left:1px solid #666;margin-left:15px;padding-left:15px;}
.top-navigation-bar .container>div{background:transparent;}
.top-navigation-bar a, .top-navigation-bar .dropdown button{color: #fff;font-size: 14px;font-weight: 700;letter-spacing: 0.42px;}
header#header{background-color:transparent;border:0;}

/*patička*/
#footer>.container{background:transparent;}
.custom-footer, .footer-bottom{border:0;}

/*ostatní*/
.homepage-box{border:0;background-color:transparent;}
.content-wrapper .container{background:#fff;padding:27px;}
.banners-row .col-sm-8{padding-left:25px;}
.homepage-box.before-carousel{margin-bottom:0;padding-bottom:0;}
.latest-contribution-box{background-color:transparent;}
.homepage-box.before-carousel .container{border-top-left-radius:10px;border-top-right-radius:10px;}
.content-wrapper:last-of-type .container{border-bottom-left-radius:10px;border-bottom-right-radius:10px;}
.h4.homepage-group-title{color: #000000;font-size: 35px;font-weight: 600;margin-top:20px;margin-bottom:60px;}
.homepage-box.welcome-wrapper{background-image:url('/user/documents/upload/šablona-nemazat/bg.jpg');background-position:center;padding:0;margin-bottom:100px;}
.homepage-box.welcome-wrapper .container{background-color:transparent;}
.homepage-box.welcome-wrapper .container .welcome-wrapper{display:flex;justify-content:flex-end;}
.welcome div{max-width:100%;}
.welcome h1{color: #fff;font-size: 31px;font-weight: 600;margin-bottom:16px;max-width:100%;}
.welcome p{color: #fff;font-size: 16px;font-weight: 600;line-height: 21.17px;}

@media(max-width:1668px){
.bannersright{left:inherit;right:0%;}
.bannersleft{left:0%;right:inherit;}
}
@media(max-width:1599px){
.bannersright{display:none;}
.bannersleft{display:none;}
}

@media (min-width: 1440px){
.container {width: 1178px;}
}

@media(max-width:992px){
body {background-position: left top; background-repeat: repeat !important;    background-attachment: scroll;    background-image: url(/user/frontend_backgrounds/bg-velky.jpg);}
.container.breadcrumbs-wrapper{padding:0;}
.breadcrumbs a{padding:5px;}
}

@media(min-width:768px){
    .navigation-in>ul>li>a {padding-left: 32px!important;padding-right: 32px!important;padding-top: 10px;padding-bottom: 10px;/* margin-bottom: 9px; */}
    #header .cart-count{padding:12px 20px;border-radius:40px; ;color: #ffffff;font-size: 16px;font-weight: 600;letter-spacing: 0.53px;background-color:#000000;}
    #navigation {    background-color: transparent;height:auto;    overflow: inherit;}
.navigation-in {background-color: transparent;position:inherit;width:100%;}
a.login, a[title*="EUR"]{    border-left: 1px solid #666;}
.top-navigation-tools>.dropdown>button{display:none;}
.top-navigation-bar .dropdown .dropdown-menu{display:flex;position:inherit;background-color:transparent;border:0;}
.top-navigation-bar-menu-helper li a::before, .dropdown-menu li a::before{background-color:transparent;}
.top-navigation-bar-menu-helper li a, .dropdown-menu li a{color: #fff; font-size: 14px;  font-weight: 700;    letter-spacing: 0.42px;}
.header-top .btn.cart-count i{    left: 42px;top: 8px;}
.top-navigation-bar .dropdown .dropdown-menu{right:-20px}
.top-navigation-bar .dropdown{margin-right:0;}
.top-navigation-bar .container>div:last-child{    display: flex; justify-content: flex-end; align-items: center;}
.header-top{height:auto;}
.footer-rows .site-name{max-widtH:233px;}
}

@media(max-width:767px){
    .navigation-in {background-color:#000;}
body:not(.navigation-window-visible) .navigation-close{display:none;}
.registrace{display:none;}
.navigation-in>ul>li{margin-bottom:0;}
.navigation-window-visible #navigation{background:#041527;}
}

@media(max-width:500px){
.breadcrumbs {display:block;padding:0 10px 0 10px;}
.navigation-home-icon-wrapper::before{display:inline-block;left:0;}
.breadcrumbs>span>a::after{margin-left:6px;}
}